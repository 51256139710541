import React, { useState } from 'react';
import styled from 'styled-components';

import { H1, Paragraph, Heading } from 'components/common/Styles';

import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const PrivacyPolicy = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<ThemeProviderComponent>
			<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
			<PaddingTopContainer isBurgerOpen={isMenuOpen}>
				<Content isOpen={isMenuOpen}>
					<TopPadding />
					<Paragraph></Paragraph>
					<H1>Privacy policy</H1>
					<Paragraph>
						Local Physio Network Pty Ltd (ABN: 26 641 519 904) (“BookPhysio.com”, “we”, “us” or “our”)
						provides users with:
					</Paragraph>
					<List>
						<Item>
							<Paragraph>
								(a) access to the Local Physio Network website available at BookPhysio.com (Site); and
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>(b) any of BookPhysio.com’s services made available on the Site.</Paragraph>
						</Item>
					</List>
					<Paragraph>(together, the “Services”)</Paragraph>
					<Paragraph>
						This Privacy Policy explains how we collect, use, maintain and disclose Personal Information
						which we collect, including Personal Information we collect from those who register as users
						(“you”, “yours”) of the Site.
					</Paragraph>
					<Paragraph>
						This Privacy Policy applies to the Services provided by us or our subsidiaries or affiliated
						companies via the Site.
					</Paragraph>
					<Paragraph>
						By accessing the Site you acknowledge and agree that you have read and understood this Privacy
						Policy and consent to the use of your Personal Information as set out in this Privacy Policy. If
						you do not agree with this Privacy Policy, please do not continue to use the Site and notify us
						immediately.
					</Paragraph>
					<Paragraph>
						This Privacy Policy does not apply to any Personal Information that you provide to another user
						on the Site or through any other means.
					</Paragraph>
					<Paragraph>
						In order to effectively optimise use of the Services, we need to collect Personal Information
						from each user and process that Personal Information. We may use common technologies such as
						cookies and web beacons to keep track of users’ interactions with the Sites to improve the
						quality of its Services. We may also collect your Internet Protocol (IP) address to track and
						aggregate non-personally identifiable information, your referring Sites addresses, browser type,
						domain name, and access times.
					</Paragraph>
					<Paragraph>
						“Personal Information” has the meaning given to it in the Privacy Act 1988 (Cth) (Act). In
						general terms, it is any information that can be used to personally identify you. This may
						include, but is not limited to, your name and contact details including phone numbers and email
						addresses. If the information we collect personally identifies you, or you are reasonably
						identifiable from it, the information will be considered Personal Information. If you have any
						questions about this Privacy Policy, you may contact us with any questions before you use the
						Sites by emailing us at
						<HoveredInline>
							<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>.
						</HoveredInline>
					</Paragraph>
					<Heading>How Your Information is Used</Heading>
					<List>
						<Item>
							<Paragraph>
								Third Party Clinics – you hereby acknowledge and agree to us sharing your Personal
								Information to third parties, such as clinics and physicians which offer physiotherapy
								services in Australia, as part of us providing the Services to you.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Information you provide – When you register with BookPhysio.com, you will be required to
								provide Personal Information. We may utilise the information you submit under your
								account in order to provide you with a better experience and to improve the quality of
								our Services, confirm your identity, manage our relationship with you and contact you.
								We also use your personal information if we are required to disclose this information to
								satisfy any applicable law, legal requirement, police investigation or request from a
								Government authority.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Payment Information – You may be required to provide payment details to us or our third
								party payment platform. We do not store payment details directly and you expressly
								acknowledge and agree that we are not liable for any loss of Personal Information
								resulting from a breach of security of the third party payment platform.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Cookies – When you use the Services, we may send one or more cookies to your computer or
								device. We may use cookies to improve the quality of our Services, including for storing
								user preferences, improving search results, and tracking user trends.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Log information – When you access the Services, our servers automatically record certain
								information. These server logs may include information such as your web request, your
								interaction with a service, IP address, browser type, browser language, the date and
								time of your request, and one or more cookies that may uniquely identify your browser or
								your account.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Communications – When you send email or other communications to us, we may retain those
								communications in order to process your inquiries, respond to your requests and improve
								our Services. We may use your email address to communicate with you about our services.
								We may send email or other communications to any person whose email address or contact
								details have been provided to us by a former or current user of the Sites. If you do not
								wish to receive these emails or other communications, you must contact us at{' '}
								<HoveredInline>
									<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>.
								</HoveredInline>
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Third Party Applications – We may make available third party applications, such as
								gadgets or extensions, during the provision of the Services. The information collected
								by us when you enable a third party application is processed under this Privacy Policy.
								Information collected by the third party application provider is governed by their own
								privacy policies.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								Other sites – This Privacy Policy applies to the Sites only. We do not exercise control
								over the sites displayed as search results, sites that include BookPhysio.com
								applications, products or services, or links from within our Sites to other sites. These
								other sites may place their own cookies or other files on your computer, collect data or
								solicit Personal Information from you.
							</Paragraph>
						</Item>
					</List>
					<Paragraph>In addition to the above, we may use the information collected to:</Paragraph>
					<List>
						<Item>
							<Paragraph>
								provide, maintain, protect, and improve our Services (including advertising services)
								and develop new Services;
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								carry out technical analysis to determine how to improve the Platform;
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>improve our service to you and your experience with us;</Paragraph>
						</Item>
						<Item>
							<Paragraph>protect the rights or property of BookPhysio.com or its Users; and</Paragraph>
						</Item>
						<Item>
							<Paragraph>comply with laws and assist government or law.</Paragraph>
						</Item>
					</List>
					<Paragraph>
						If we use this information in a manner different than the purpose for which it was collected,
						then we will ask for your consent prior to such use.
					</Paragraph>
					<Paragraph>
						We process Personal Information on our servers in Sydney, Australia and you consent to your
						Personal Information being processed and in that location.
					</Paragraph>
					<Heading>Information sharing and onward transfer</Heading>
					<Paragraph>
						We will share Personal Information with other companies or individuals in the following
						circumstances:
					</Paragraph>
					<List>
						<Item>
							<Paragraph>
								(a) When we have your consent. We will usually require opt-in consent for the sharing of
								any sensitive Personal Information.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								(b) When we provide such information to our subsidiaries, affiliated companies or other
								trusted businesses or persons for the purpose of processing personal information on
								BookPhysio.com’s behalf. We require that these parties agree to process such information
								based on BookPhysio.com’s instructions and in compliance with this Privacy Policy and
								any other appropriate confidentiality and security measures.
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								(c) When we have a good faith belief that access, use, preservation or disclosure of
								such information is reasonably necessary to:
								<SecondList>
									<Item>
										<Paragraph>
											(i) satisfy any applicable law, regulation, legal process or enforceable
											governmental request;
										</Paragraph>
									</Item>
									<Item>
										<Paragraph>
											(ii) enforce BookPhysio.com’s Terms of Service, including investigation of
											potential violations of those Terms;
										</Paragraph>
									</Item>
									<Item>
										<Paragraph>
											(iii) detect, prevent, or otherwise address fraud, security or technical
											issues; or
										</Paragraph>
									</Item>
									<Item>
										<Paragraph>
											(iv) protect against harm to the rights, property or safety of
											BookPhysio.com, its users or the public as required or permitted by law.
										</Paragraph>
									</Item>
								</SecondList>
							</Paragraph>
						</Item>
					</List>
					<Paragraph>
						If BookPhysio.com becomes involved in a merger, acquisition, or any form of sale of some or all
						of its assets, BookPhysio.com will use BookPhysio.com’s best endeavors to:
					</Paragraph>
					<List>
						<Item>
							<Paragraph>
								(a) ensure the confidentiality of any personal information involved in such
								transactions; and
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								(b) provide notice before your personal information is transferred and becomes subject
								to a different privacy policy.
							</Paragraph>
						</Item>
					</List>
					<Heading>Storing Personal Information</Heading>
					<Paragraph>
						We store your Personal Information on secured servers on Amazon Web Services which are stored in
						Australia.
					</Paragraph>
					<Paragraph>
						Your Personal Information, even if collected by us in Australia, may be transferred to and
						stored in a country that is not Australia (on a temporary or permanent basis). By registering as
						a user of and accessing the Site, you agree:
					</Paragraph>
					<List>
						<Item>
							<Paragraph>
								(a) to and authorise us giving your Personal Information, in accordance with this
								Privacy Policy, to a third party who is not in Australia or a third party who may
								transfer and store outside Australia your personal information (Overseas Recipient);
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								(b) that, while we will take reasonable steps to ensure that an Overseas Recipient does
								not breach the APPs, Overseas Recipients are not bound by the APPs and subclause 8.1 of
								the APPs does not apply to the disclosure of your personal information to an Overseas
								Recipient*; and
							</Paragraph>
						</Item>
						<Item>
							<Paragraph>
								(c) that, if an Overseas Recipient handles your information in breach of the APPs, you
								will not be able to seek redress under the Act.
							</Paragraph>
						</Item>
					</List>
					<Paragraph>
						*Subclause 8.1 of the APPs requires us to ensure that Overseas Recipients comply with the APPs
						unless, in accordance with subclause 8.2, you agree otherwise. For more information, please
						check the Office of the Australian Information Commissioner (OAIC) website.
					</Paragraph>
					<Heading>Information security</Heading>
					<Paragraph>
						We acknowledge that we will take appropriate security measures to protect against unauthorised
						access to or unauthorised alteration, disclosure or destruction of data. These measures may
						include internal reviews of our data collection, storage and processing practices and security
						measures, including appropriate encryption and physical security measures to guard against
						unauthorised access to systems where we store personal data.
					</Paragraph>
					<Heading>Accessing and updating Personal Information</Heading>
					<Paragraph>
						When you use the Services, we make good faith efforts to provide you with access to your
						Personal Information and either to correct this data if it is inaccurate or to delete such data
						at your request if it is not otherwise required to be retained by law or for legitimate business
						purposes.
					</Paragraph>
					<Paragraph>
						We ask individual users to identify themselves and the information requested to be accessed,
						corrected or removed before processing such requests, and we may decline to process requests
						that are unreasonably repetitive or systematic, require disproportionate costs and/or technical
						effort, jeopardise the privacy of others, or would be extremely impractical (for instance,
						requests concerning information residing on backup tapes), or for which access is not otherwise
						required. Because of the way we maintain certain Services in connection with the Sites, you
						acknowledge that residual copies may take a period of time before they are deleted from our
						active servers and may remain in our backup systems.
					</Paragraph>
					<Heading>Complaints</Heading>
					<Paragraph>
						When we receive formal written complaints, it is our policy to contact the complaining user
						regarding his or her concerns.
					</Paragraph>
					<Heading>Changes to this Privacy Policy</Heading>
					<Paragraph>
						We may amend or vary this Privacy Policy from time to time by publishing the amended Privacy
						Policy on the Site.
					</Paragraph>
					<Paragraph>Published: 24 December 2021</Paragraph>
					<Paragraph>Last Updated: 25 November 2022</Paragraph>
				</Content>
				<Footer />
			</PaddingTopContainer>
		</ThemeProviderComponent>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;
const List = styled.ul``;
const SecondList = styled.ul``;
const Item = styled.li`
	margin: 0 0 0 40px;
`;
const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
`;
export default PrivacyPolicy;
